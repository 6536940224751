.category-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.category-items > li {
  color: rgb(0, 0, 0);
  padding: 3px 2px;
  list-style: none;
  /* padding-left: 20px; */
  padding-right: 20px;
  text-transform: capitalize;
  display: flex;
}

/* .category-items > li::after{ */
/* content:url("../../image/down-arrow-svgrepo-com.svg"); */
/* padding-left: 5px;
    color: blue;
    cursor: pointer;
   
} */

/* .category-items > li:hover::after{ */
/* content: url("../../image/up-arrow-svgrepo-com.svg"); */
/* padding-left: 5px;
    color: blue;
    cursor: pointer;
} */

/* .category-items > li:hover{
    color: blue;
} */

.category-items > li > ul {
  position: absolute;
  height: auto;
  background-color: rgb(255, 255, 255);
  /* left: 0;
    right: 0; */
  color: rgb(0, 0, 0) !important;
  display: none;
  /* margin-left: 8px; */
  margin-right: 8px;
  margin-top: 30px;
  padding-bottom: 10px;
  padding-top: 10px;
  box-shadow: 3px 1px 1px rgb(158, 158, 158);
  border-radius: 2px;
  /* display: flex; */
  /* flex-direction: column; */
  /* padding-left: 30px; */
}

.category-items > li > li {
  font-size: 13px;
  /* font-weight: 400; */
  /* margin-left: 12px; */
  opacity: 0.9;
  padding-right: 10px;
  padding-bottom: 10px;
}

/* .category-items > li a:hover{
    color: blue;
} */

/* .category-items > li a:hover::after{
    content: url("../../image/right-arrow.png");
    padding-left: 8px;
    cursor: pointer;
} */

.category-items > li > ul > li {
  /* float: ; */

  margin-left: 10px;
  font-size: 15px !important;
  /* width: 150px; */
}

.category-items > li > ul > li > a {
  font-weight: 600;
  font-size: 15px;
  /* width: 250px; */
  text-transform: capitalize;

  /* margin-left: 5px; */
}

.category-items > li > span {
  /* display: block; */
  /* line-height: 40px; */
  /* padding: 0, 20px; */
  /* width: 100px; */
  font-size: 15px;
  padding-left: 5px;
}

.category-items > li > span:hover {
  color: blue;
}

.category-items > li:hover ul {
  display: block;
  display: flex;
  flex-direction: column;
  list-style: none;
}

@media only screen and (max-width: 1024px) {
  .category-items > li {
    color: rgb(0, 0, 0);
    padding: 3px 2px;
    list-style: none;
    padding-left: 5px;
    padding-right: 5px;
    text-transform: capitalize;
    display: flex;
  }
  .category-items > li > span {
    width: 100px;
    font-size: 10px;
  }
  /* .category-items{
        /* overflow-x: scroll; */
  /* } */
  .category-items > li > ul > li > a {
    font-weight: 400;
    font-size: 12px !important;
    /* width: 250px; */
    text-transform: capitalize;
  }
  .category-items > li > ul {
    left: 0;
    right: 0;
    box-shadow: none;
    border-radius: 0px;
  }
}

@media only screen and (max-width: 300px) {
  .category-items {
    overflow: auto;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .category-items > li {
    color: rgb(0, 0, 0);
    padding: 2px;
    list-style: none;
    padding-left: 5px;
    padding-right: 5px;
    text-transform: capitalize;
    display: flex;
  }
  .category-items > li a {
    font-size: 5px;
    /* font-weight: 400; */
    /* margin-left: 12px; */
    opacity: 0.9;
  }
  .category-items > li > span {
    font-size: 10px;
    width: 100px;
  }
  .category-items > li > ul > li > a {
    font-weight: 400;
    font-size: 12px !important;
    /* width: 250px; */
    text-transform: capitalize;
  }
}
