.videoplay{
    width: 100%;
    height: 400px;
    object-fit:contain;
    z-index: -100;
}

/* .mainApp{
    background;
} */

.videoitems{
    width: 100%;  
    height: 350px;
}
.slick-prev{
    font-size: 30px;
    left: 0px !important;
    background-color: #b9bbbe !important;
    width: 40px !important;
    height: 70px !important;
    color: black !important;
    z-index: 10 !important;
}

.slick-prev:before {
    content: '<' !important;
    color: rgb(255, 255, 255) !important;
    font-size: 25px !important;
    font-weight: 600 !important;
}

.slick-next{
    font-size: 30px;
    right: 0px !important;
    background-color: #b9bbbe !important;
    width: 40px !important;
    height: 70px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: black !important;
    z-index: 10 !important;
}

/* .slick-next:hover{
    background-color: red !important;
} */

.slick-next:before {
    content: '>' !important;
    color: rgb(255, 255, 255) !important;
    font-size: 25px !important;
    font-weight: 600 !important;
}