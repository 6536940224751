

.pagination{
    display: flex;
    align-items: center;

}

.pagination li {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

 .pagination .previous{
    width: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination .next{
   width: 50px;
   height: 30px;
   display: flex;
   align-items: center;
   justify-content: center;
}
.activeItem{
    border: none;
    background-color: rgb(64, 104, 165);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: white;
}






.filter_page{
    width: 300px;
}

.product_page{
    width: calc(100%-300px) !important;
}

.horizontal-slider{
    background-color: #2874f0;
    margin-top: 30px;
    margin-bottom: 20px;
    color: white;
    cursor: pointer;
}

.example-thumb {
    top: -30px;
    width: auto;
    height:auto;
    background-color: #2874f0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 70%;
    border: 1px solid white;
    cursor: pointer;
}

.sidebar{
    position: relative;
    z-index: 1;
    width: 300px;
    border: 2px;
    left: 30px;
    top: 0px;
    scroll-behavior: smooth;
    animation: ease-in-out .3s;    
    /* border: 2px solid rgb(88, 88, 88); */
    padding-bottom: 20px;
    padding-top: 20px;
    border-radius: 5px;
}

.sidebar.fixed{
    position: sticky;
    scroll-behavior: smooth;    
    animation: ease-in-out .3s;
    width: 300px;
    left: 40px !important;
    top: 100px !important;
    margin-left: 30px;
}



.categoryProduct{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
}
.categoryProduct > li{
    color: rgb(0, 0, 0);
    padding: 3px 2px;
    /* list-style: none; */
    /* padding-left: 20px; */
    padding-right: 20px;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
}

.categoryProduct > li::after{
    /* content:url("../../image/down-arrow-svgrepo-com.svg"); */
    padding-left: 5px;
    color: blue;
    cursor: pointer;
    cursor: default;
   
}

.categoryProduct > li:hover::after{
    /* content: url("../../image/up-arrow-svgrepo-com.svg"); */
    padding-left: 5px;
    /* color: blue; */
    cursor: pointer;
}



.categoryProduct > li a {
    font-size: 13px;
    /* font-weight: 400; */
    /* margin-left: 12px; */
    opacity: 0.9;
    background-color: red;

}

.categoryProduct > li a:hover{
    color: blue;
    cursor: default;
}

/* .categoryProduct > li a:hover::after{
    content: url("../../image/right-arrow.png");
    padding-left: 8px;
    cursor: pointer;
} */




.categoryProduct > li > ul > li{
    float: left;
    /* margin-left: 20px; */
    font-size: 15px !important;
    display: flex;
    flex-direction: column;
    /* width: 250px; */
}

.categoryProduct > li > ul > li > a{
    font-weight: 600;
    font-size: 16px;
    /* width: 250px; */
    text-transform: capitalize;
    flex-direction: column;
}

.categoryProduct> li >span{
    /* display: block; */
    /* line-height: 40px; */
    /* padding: 0, 20px; */
    /* width: 100px; */
    font-size: 15px;
}

.categoryProduct > li > span:hover{
    color: blue;
}

.categoryProduct > li:hover  ul{
    display: block;
    list-style: none;
}

@media only screen and (max-width: 600px) {
    .categoryProduct > li{
        color: rgb(0, 0, 0);
        padding: 3px 2px;
        list-style: none;
        padding-left: 5px;
        padding-right: 5px;
        text-transform: capitalize;
        display: flex;
    }
    .categoryProduct> li >span{
        width: 100px;
        font-size: 10px;
    }
    /* .categoryProduct{
        /* overflow-x: scroll; */
    /* } */ 
    .categoryProduct > li > ul > li > a{
        font-weight: 400;
        font-size: 12px !important;
        /* width: 250px; */
        text-transform: capitalize;
    }
    .filter_page{
        width: 200px;
    }
    .sidebar{
 
        width: 200px;
        left: 5px;
      
    }
    
    .sidebar.fixed{
        width: 200px;
    
    }
}

@media only screen and (max-width: 300px) {
    .categoryProduct{
        overflow: auto;
     }
     ::-webkit-scrollbar { 
        display: none; 
    } 
    .no-scrollbar{
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .categoryProduct > li{
        color: rgb(0, 0, 0);
        padding: 2px;
        list-style: none;
        padding-left: 5px;
        padding-right: 5px;
        text-transform: capitalize;
        display: flex;
    }
    .categoryProduct > li a {
        font-size: 5px;
        /* font-weight: 400; */
        /* margin-left: 12px; */
        opacity: 0.9;
    }
    .categoryProduct> li >span{
        font-size: 10px;
        width: 100px;
    }
    .categoryProduct > li > ul > li > a{
        font-weight: 400;
        font-size: 12px !important;
        /* width: 250px; */
        text-transform: capitalize;
    }
}