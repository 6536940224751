.image-container {
    position: relative;
    width: 208px; /* 52 * 4 = 208px */
    height: 208px; /* 52 * 4 = 208px */
    overflow: hidden;
  }
  
  .image-container img {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease-in-out; /* Smooth transition */
  }
  
  .image-container img.first-image {
    opacity: 1;
    z-index: 0; /* Ensure the first image is below the second */
  }
  
  .image-container img.second-image {
    opacity: 0;
    z-index: 2; /* Ensure the second image is above the first */
  }
  
  .image-container.hovered img.first-image {
    opacity: 0; /* Fade out the first image on hover */
  }
  
  .image-container.hovered img.second-image {
    opacity: 1; /* Fade in the second image on hover */
  }
  