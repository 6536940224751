.redColor{
    color: red;
}
.greenColor{
    color: green;
}


/* 
.slick-prev {
    left: 30;
} */

.previous  .page-number{
    background-color: gray;
    color: white;
    padding: 3px 10px 3px 10px;
    margin-right: 3px;
}

.next .page-number{
    background-color: gray;
    color: white;
    padding: 3px 10px 3px 10px;
    margin-left: 3px;
}