.shopingOrder{
    border: 1px solid black;
    border-radius: 3px;
    display: block;
    background: linear-gradient(to right, rgb(231, 241, 45) 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
    display: flex;
    align-items: center;
  }
  
  .shopingOrder:hover {
    background-position: left bottom;
    border: none;
    color: white;
  }
  
  .text {
    text-align: center;
    font-size: 16px;
    color: black;
    transition: all .6s ease-out;
    display: block;
  }
  
  .text:hover {
    color: white;  
  }