

 .product_image{
    width: 480px !important;
    /* height: 500px !important; */
 }

 .product_img{
    position: relative;
    top: 0px;
    left: 30px;
    display: flex;
    /* flex-direction: column; */
    scroll-behavior: smooth;
    animation: ease-in-out .3s;  
    margin-right: 10px;
    /* height: 500px !important; */
 }

 .product_img.fixed{
    position: sticky;
    width: 480px !important;
    top: 100px;
    scroll-behavior: smooth;
    animation: ease-in-out 0.3s  

 }

.iiz__img   {
   height: 100% !important;
}

.scrollImge{
   overflow-x: scroll !important;
    overflow-y: hidden !important;
    white-space:nowrap !important;
    width: 100% !important;
}
 
 .img_wrap {
   width: 60px !important;
   height: 60px;
   border: 1.5px solid #0000007a;
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 3px;
}
.img_wrap img {
   width: 60px !important;
   height: 60px;
   object-fit: contain;
}

.active {
   border: 2px solid #e77600;
}
 
 /* rounded-xl flex  justify-center flex space-y-4 */


 @media only screen and (max-width: 760px) {
      .product_image{
         width: 100% !important;
         height: 100% !important;
         display: hidden !important;
      }
      .product_img{
         left: 0px !important;
      }
}