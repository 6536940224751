/* .css-yrdy0g-MuiDataGrid-columnHeaderRow{
    background-color: rgb(234, 67, 67);
    color: white;
}

.MuiDataGrid-columnHeaderDraggableContainer{
    color: white;
} */

.css-yrdy0g-MuiDataGrid-columnHeaderRow{
    background-color: rgb(57, 57, 57);
    color: white;
}

.redColor{
    color: red;
}
.greenColor{
    color: green;
}
.progressBarColor{
    background-color: rgba(57, 110, 57, 0.904);
}

.itemColor{
    background-color: rgba(103, 103, 103, 0.605);
}


@keyframes progressBarAnimation {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }