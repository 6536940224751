
 .product_imgs{
    position: relative;
    top: 0px;
    /* left: 30px; */
    display: flex;
    scroll-behavior: smooth;
    animation: ease-in-out .3s;  
 }

 .product_imgs .fixed{
    position: sticky;
    top: 100px;
    margin-right: 0px;
    scroll-behavior: smooth;
    animation: ease-in-out .3s;  
 }
