.navbar{
    display: flex;
    align-items: center;
    justify-content:space-between;
    width: 100%;
    height: 15px;
    padding: 25px 30px 25px 30px;
    background-color: #800000;
    z-index: 30 !important; 
}

.navbar.fixed{
    background-color: #800000;
    transition: .3s ease-in-out;
    scroll-behavior: smooth;
    animation: ease-in-out .3s; 
    width: 100%;
}

.navbar.fixed .icons{
    color: black;
}

.navbar .icons{
    color: rgb(2, 2, 2);
}

@media only screen and (max-width: 760px) {
    .navbar{
        padding-left: 5px;
        padding-right: 5px;
        justify-content: baseline;
    }
}