*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



::-webkit-scrollbar{
  width: 10px;
  border-radius: 10px;
}


::-webkit-scrollbar-thumb {
  background-color: rgb(151, 151, 151);
}


@tailwind base;
@tailwind components;
@tailwind utilities;