.spinner {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: radial-gradient(farthest-side,#585858 94%,#0000) top/9px 9px no-repeat,
           conic-gradient(#afc6e100 30%,#3a5b77a3);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 0);
    animation: spinner-c7wet2 1s infinite linear;
 }
 
 @keyframes spinner-c7wet2 {
    100% {
       transform: rotate(1turn);
    }
 }